var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("服务查询 "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)],1),_c('v-card-text',[_c('v-divider',{staticClass:"py-2"}),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-wrap',{attrs:{"label":"状态","cols":"4"}},[_c('v-select',{attrs:{"items":_vm.$select.serviceStatus,"item-text":"name","item-value":"key","clearable":"","dense":""},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}})],1)],1)],1)],1)])],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":_vm.add}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$tip.button.add)+" ")],1),_c('v-btn',{attrs:{"color":"success","tile":""},on:{"click":_vm.query}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.$tip.button.query)+" ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"item-key":"key","locale":"zh-Hans","loading":_vm.loading,"headers":_vm.headers,"items":_vm.result,"options":_vm.options,"server-items-length":_vm.resultLength,"footer-props":{itemsPerPageOptions: [5,10,20]}},on:{"update:options":[function($event){_vm.options=$event},_vm.query]},scopedSlots:_vm._u([{key:"item.operTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatter.toDateTimeString(item.operTime)))]}},{key:"item.userCompanyKey",fn:function(ref){
var item = ref.item;
return [_c('v-table-item-link',{attrs:{"link":"company","items":_vm.companyList,"value":item.userCompanyKey}})]}},{key:"item.userKey",fn:function(ref){
var item = ref.item;
return [_c('v-table-item-link',{attrs:{"link":"user","items":_vm.userList,"value":item.userKey}})]}},{key:"item.operatorUserKey",fn:function(ref){
var item = ref.item;
return [_c('v-table-item-link',{attrs:{"link":"sale","items":_vm.userList,"value":item.operatorUserKey}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-table-item-link',{attrs:{"items":_vm.statusInfoList,"value":item.status}})]}},{key:"item.operatorUserTeamKey",fn:function(ref){
var item = ref.item;
return [_c('v-table-item-link',{attrs:{"items":_vm.teamList,"value":item.operatorUserTeamKey}})]}},{key:"item.action",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth('service.serviceReport.reportMain'))?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.report(item.key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.reportNum>0?'green':'gray'}},[_vm._v("mdi-file-multiple")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("报告制作")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth('service.service.load'))?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.load(item.key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-text")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("查看详情")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth('service.service.save'))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.edit(item.key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("编辑")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth('service.service.serviceOver')&&item.status===1)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.serviceOver(index,item.key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-television-off")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("服务完成")])])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }