import Vue from "vue";
import display from "@/configure/display/service/service";
import {ServiceEntity, ServiceQuery} from "@/core/entity/service/service";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";
import {CompanyEntity} from "@/core/entity/core/company";
import {UserEntity, UserTeamEntity} from "@/core/entity/core/user";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.old.serviceNo, value: 'old.serviceNo'},
            // {text: display.applyKey, value: 'applyKey'},
            {text: display.vesselKey, value: 'vesselKey'},
            {text: display.vesselName, value: 'vesselName'},
            {text: display.userKey, value: 'userKey'},
            {text: display.userCompanyKey, value: 'userCompanyKey'},
            {text: display.operatorUserKey, value: 'operatorUserKey'},
            {text: display.operatorUserTeamKey, value: 'operatorUserTeamKey'},
            // {text: display.routeName, value: 'routeName'},
            {text: display.status, value: 'status'},
            {text: display.operOperatorName, value: 'operOperatorName'},
            {text: display.operTime, value: 'operTime'},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <ServiceQuery>{
            status:1,
            sq:{
               order: 'self.operTime desc'
            }},
        result: <ServiceEntity[]>[],
        resultLength: 0,
        datetime: "",
        companyList:[] as Array<CompanyEntity>,
        userList:[] as Array<UserEntity>,
        teamList: [] as Array<UserTeamEntity>,
        statusInfoList:[
            {
                key:-1,
                name:'废弃'
            },
            {
                key:0,
                name:'停用'
            },
            {
                key:1,
                name:'正在服务'
            },
            {
                key:2,
                name:'服务完成'
            }
        ]
    }),
    async mounted() {
        this.companyList=await service.company.query({});
        this.userList=await service.user.query({});
        this.teamList = await service.userTeam.query({});
    },
    methods: {
        async query() {
            this.loading = true;
            try {
                // console.log("search");
                const query=this.$queryData(this.data, this.options);
                query.sq!.order='self.operTime desc';
                query.teamKeyList=this.$user.teamList.map(p=>p.key);
                const result = await service.service.query(query);
                if(result.length>0){
                    const aisList=await service.ais.infoBatch({mmsi:result.map(p=>p.vesselKey)});
                    result.forEach(p=>{
                        const index=aisList.findIndex(q=>q.mmsi===p.vesselKey);
                        if(index>=0){
                            p.vesselName=aisList[index].name;
                        }
                    })
                }
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/service/add"});
        },
        async report(key: number) {
            await this.$router.push({path: "/service/report/" + key});
        },
        async load(key: number) {
            await this.$router.push({path: "/service/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/service/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.service.del(key);
                await this.query();
            }
        },

        async serviceOver(index:number,key: number) {
            if (await this.$confirm(tip.confirm.serviceOver)) {
                const itemData=await service.service.serviceOver({key});
                Vue.set(this.result,index,itemData);
            }
        },
    }
});
