import Vue from "vue";
import display from "@/configure/display/service/route";
import {RouteEntity, RouteQuery} from "@/core/entity/service/route";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";
import {CompanyEntity} from "@/core/entity/core/company";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.name, value: 'name'},
            {text: display.userCompanyKey, value: 'userCompanyKey'},
            {text: display.operOperatorName, value: 'operOperatorName'},
            {text: display.operTime, value: 'operTime'},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <RouteQuery>{},
        result: <RouteEntity[]>[],
        resultLength: 0,
        datetime: "",
        companyList:[] as Array<CompanyEntity>,
    }),
    async mounted() {
        this.companyList=await service.company.query({});
    },
    methods: {
        async query() {
            this.loading = true;
            try {
                const query=this.$queryData(this.data, this.options);
                const result = await service.route.query(query);
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/route/add"});
        },
        async load(key: number) {
            await this.$router.push({path: "/route/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/route/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.route.del(key);
                await this.query();
            }
        },
    }
});
